<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l lhx40">
        <el-input
          class="w200 mr10 mb20"
          placeholder="請輸入廣告名稱"
          clearable
          v-model="filters.title"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <div>
          <el-button type="primary" @click="search">搜尋</el-button>
          <el-button type="primary" @click="openCreateDialog">新增</el-button>
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <categoryMenus
        @changeCategory="changeCurCategory"
        :style="{
          'max-height': tableHeight + 'px',
          height: tableHeight + 'px',
        }"
      />
      <el-main>
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="廣告名稱" prop="title"></el-table-column>
          <el-table-column prop="link" label="鏈接地址"></el-table-column>
          <el-table-column label="顯示權重" prop="sort"></el-table-column>
          <el-table-column label="備註">
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.note"
                placement="top-start"
                title="備註"
                width="200"
                trigger="hover"
                :content="scope.row.note"
              >
                <div class="c-note" slot="reference">{{ scope.row.note }}</div>
              </el-popover>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="展示狀態">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.show"
                :active-value="0"
                :inactive-value="1"
                active-color="#13ce66"
                inactive-color="#ccc"
                @change="togglePublish(scope.row.show, scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" @click="openEditDialog(scope.$index)"
                >編輯</el-link
              >
              <el-popconfirm
                confirmButtonText="確定"
                cancelButtonText="取消"
                icon="el-icon-info"
                iconColor="red"
                title="確定刪除嗎？"
                @onConfirm="handleDelete(scope.$index)"
              >
                <el-link slot="reference" type="danger" class="ml10"
                  >删除</el-link
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <createDialog
      :show="showCreateDialog"
      :cid="categoryId"
      :item="curItem"
      :type="type"
      @close="showCreateDialog = false"
      @confirm="confirm"
    ></createDialog>
  </div>
</template>
<script>
import { calTableHeight } from "@/common/tool"
import createDialog from "./components/createGRIndexDialog.vue"
import categoryMenus from "./components/menu.vue"
export default {
  components: { createDialog, categoryMenus },
  data() {
    return {
      categoryId: -1,
      loadList: false,
      showCreateDialog: false,
      curItem: {},
      curIndex: -1,
      type: "create",
      filters: {
        title: "",
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    // this.getCats()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async getDataList(page = 1) {
      this.loadList = true
      let res = await this.api.ads.getGRIndex({
        page,
        ...this.filters,
        id: this.categoryId,
      })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    /**
     * @method: resetFilters 重置筛选条件
     */
    resetFilters() {
      this.filters = {
        title: "",
      }
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    openCreateDialog() {
      this.type = "create"
      this.showCreateDialog = true
    },
    openEditDialog(index) {
      this.type = "edit"
      this.curIndex = index
      this.curItem = this.tableData.data[index]
      this.showCreateDialog = true
    },
    confirm(data) {
      if (this.type == "edit") {
        this.editData(data)
      } else {
        this.createData(data)
      }
    },
    close() {
      this.showCreateDialog = false
    },
    /**
     * @method: createData 打开新增弹窗
     */
    async createData(data) {
      let res = await this.api.ads.createGRIndex({ ...data })
      if (res && res.code == 0) {
        this.$message({
          type: "success",
          message: "創建成功",
        })
        this.getDataList()
        this.showCreateDialog = false
      }
    },
    async editData(data) {
      let res = await this.api.ads.editGRIndex({ ...data })
      if (res && res.code == 0) {
        this.$message({
          message: "更新成功",
          type: "success",
        })
        this.showCreateDialog = false
        this.getDataList(this.tableData.current_page)
      }
    },
    async handleDelete(index) {
      let item = this.tableData.data[index]
      if (item.show == 0) {
        this.$message.warning("顯示狀態數據不可刪除，請先隱藏")
      } else {
        let res = await this.api.ads.deleteGRIndex(item.id)
        if (res && res.code == 0) {
          this.$message({
            message: "刪除成功",
            type: "success",
          })
          this.tableData.data.splice(index, 1)
        }
      }
    },
    togglePublish(value, index) {
      if (value == 1) {
        this.unPublish(index)
      } else {
        this.publish(index)
      }
    },
    async unPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.ads.switchGRIndex(id, 1)
      if (res && res.code == 0) {
        console.log(res.data)
        // this.tableData.data[index].show = 1;
        this.$message({
          message: "隱藏成功",
          type: "success",
        })
      }
    },
    async publish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.ads.switchGRIndex(id, 0)
      if (res && res.code == 0) {
        // this.tableData.data[index].show = 0;
        this.$message({
          message: "顯示成功",
          type: "success",
        })
      }
    },
    changeCurCategory(data) {
      this.categoryId = data.id
      this.getDataList()
    },
  },
}
</script>
